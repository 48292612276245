<template>
  <b-card
    class="brandarchiveresult-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandarchiveresultForm" class="edit-form mt-2">


    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import brandarchiveresultStore from './brandarchiveresultStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      brandarchiveresult: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandarchiveresult')) store.registerModule('brandarchiveresult', brandarchiveresultStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandarchiveresult')) store.unregisterModule('brandarchiveresult')
    })

    const edit = function() {
      store.dispatch('brandarchiveresult/edit', {id: this.id}).then(res => {
        this.brandarchiveresult = res.data.data
      })
    }

    const view = function() {
      store.dispatch('brandarchiveresult/view', {id: this.id}).then(res => {
        this.brandarchiveresult = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('brandarchiveresult/save', this.brandarchiveresult).then(res => {
        toast.success('数据已保存!')
        //this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
